
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store,
} from "@/components/CyanPageWrapper.vue";
import { IonButton, IonIcon, IonCol, IonRow, IonGrid, IonInput, IonLabel, IonItem, alertController, IonCheckbox } from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import { closeCircleOutline, swapHorizontal } from "ionicons/icons";
import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "DirectorioMemberStreamlined",
  components: {
    CyanPageWrapper,
    IonPage,
    IonButton,
    IonIcon,
    IonCol,
    IonLabel,
    IonItem,
    IonCheckbox,
    IonRow,
    IonGrid,
    IonInput
  },
  setup() {
    return {
      closeCircleOutline,
      swapHorizontal
    };
  },
  data() {
    return {
      duiCargoRaw: "",
      telCargoRaw: "",
      nombreCargo: "",
      sinDui: false
    };
  },
  computed: {

    noNecesitaDui() {
      return !! (this as any).memberData.noNecesitaDui
    },

    checkSinDui() {
      return (this as any).noNecesitaDui && (this as any).sinDui;
    },

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    idMember(): any {
      return (this as any).$route.params.id || 0;
    },
    memberData(): any {
      const s = store.getters.directorioStreamlined;
      if (!s || !s.value || !s.value.datosCargos || !s.value.datosCargos[(this as any).$route.params.id])
        return {};

      return s.value.datosCargos[(this as any).$route.params.id];
    },
    duiCargo(): string {
      const f = this.duiCargoRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    telCargo(): string {
      let f = this.telCargoRaw.replace(/[^0-9]/g, "");
      if (f.length > 0 && (f[0] != '6' && f[0] != '7')) f = '';
      if (f.length <= 4) return f;
      return f.substr(0, 4) + "-" + f.substr(4, 4);
    },
    backRoute() {
      return '/jrslasist/' + (this as any).idMember;
    },
  },
  watch: {
  },
  methods: {
    syncTelCargoRaw: function () {
      if (this.telCargoRaw != this.telCargo) {
        this.telCargoRaw = this.telCargo;
        const el = this.$refs.telCargo as any;
        if (el) {
          el.value = this.telCargo; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.telCargo;
        }
      }
    },
    syncDuiCargoRaw: function () {
      if (this.duiCargoRaw != this.duiCargo) {
        this.duiCargoRaw = this.duiCargo;
        const el = this.$refs.duiCargo as any;

        if (el) {
          el.value = this.duiCargo; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.duiCargo;
        }
      }
    },

    async replaceMember() {
      const problemas = [];

      if (!this.sinDui) {
        if (this.duiCargo.length != 10)
          problemas.push("Debe especificar un DUI válido.");

        if (this.telCargo.length != 0 && this.telCargo.length != 9)
          problemas.push('El teléfono no es válido.')
      } else {
        if (this.telCargo.length != 9)
          problemas.push('El teléfono no es válido.')
        if (this.nombreCargo.split(' ').filter((x) => x.length).length < 2)
          problemas.push('Debe especificar nombre y apellidos.')
      }


      if (problemas.length) {
        alertController
          .create({
            message: problemas.join(" "),
            buttons: ["OK"],
          })
          .then((alert) => alert.present());

        return;
      }

      const _t = this as any;

      // Preflight

      const preflight = await cyanRequest("jrsl/replaceCargo", {
        isPost: true,
        isModal: true,
        needsAuth: true,
        retries: 3,
        params: {
          replacingId: _t.memberData.id,
          dui: _t.duiCargo,
          sinDui: _t.checkSinDui ? '1' : '0',
          telefono: _t.telCargo,
          nombre: _t.nombreCargo
        },
      });

      if (!preflight.ok) return;



      if (
        !(await new Promise(function (resolve) {
          alertController
            .create({
              message: preflight.message as string,
              buttons: [
                {
                  text: "NO",
                  role: "cancel",
                  handler: function () {
                    resolve(false);
                  },
                },
                {
                  text: "SÍ",
                  handler: function () {
                    resolve(true);
                  },
                },
              ],
            })
            .then((alert) => alert.present());
        }))
      ) {
        return;
      }

      cyanRequest("jrsl/replaceCargo", {
        isPost: true,
        isModal: true,
        needsAuth: true,
        retries: 3,
        params: {
          replacingId: _t.memberData.id,
          dui: _t.duiCargo,
          telefono: _t.telCargo,
          sinDui: _t.checkSinDui ? '1' : '0',
          nombre: _t.nombreCargo,
          commit: 1
        },
      }).then(function (d) {
        if (d.ok) {
          _t.duiCargoRaw = "";
          _t.telCargoRaw = "";
          _t.sinDui = false;
          _t.nombreCargo = '';
          _t.$router.push('/jrslasist');
          store.commit("storeDirectorioStreamlined", d);

          alertController
            .create({
              message: "Cargo sustituido con éxito.",
              buttons: ["OK"],
            })
            .then((alert) => alert.present());
        }
      });
    },
  },
});
